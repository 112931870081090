import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"

import tick from "../images/tick.svg"
import plants from "../images/plants.svg"

const HelpList = props => {
  return(
    <Container fluid="xl" id="things-i-can-help-with">
      <Row>
        <Col xs={12} lg={{span:10, offset:1}} className="my-3 text-center">
          <h1>Things I can help with</h1>
        </Col>
        <Col xs={12} sm={{span:8,offset:2}} md={{span:5, offset:1}} lg={{span:4,offset:2}}>
        <div className="list-group list-group-flush">
          <div className="list-group-item d-flex justify-content-between border-bottom-0">
            Stress
              <img
                src={tick}
                width="20"
                height="20"
                alt=""
              />
          </div>
          <div className="list-group-item d-flex justify-content-between border-bottom-0">
            Suicidal feelings
              <img
                src={tick}
                width="20"
                height="20"
                alt=""
              />
          </div>
          <div className="list-group-item d-flex justify-content-between border-bottom-0">
            Trauma
              <img
                src={tick}
                width="20"
                height="20"
                alt=""
              />
          </div>
          <div className="list-group-item d-flex justify-content-between border-bottom-0">
            Bullying
              <img
                src={tick}
                width="20"
                height="20"
                alt=""
              />
          </div>
          <div className="list-group-item d-flex justify-content-between border-bottom-0">
            Depression and Anxiety
              <img
                src={tick}
                width="20"
                height="20"
                alt=""
              />
          </div>
          <div className="list-group-item d-flex justify-content-between border-bottom-0">
            Self-esteem and confidence
              <img
                src={tick}
                width="20"
                height="20"
                alt=""
              />
          </div>
          <div className="list-group-item d-flex justify-content-between border-bottom-0">
            Sexuality and Gender
              <img
                src={tick}
                width="20"
                height="20"
                alt=""
              />
          </div>
        </div>
        </Col>
        <Col xs={12} sm={{span:8,offset:2}}  md={{span:5, offset:0}} lg={{span:4,offset:0}}>
        <div className="list-group list-group-flush">
          <div className="list-group-item d-flex justify-content-between border-bottom-0">
            Relationship issues
              <img
                src={tick}
                width="20"
                height="20"
                alt=""
              />
          </div>
          <div className="list-group-item d-flex justify-content-between border-bottom-0">
            Death and Illness
              <img
                src={tick}
                width="20"
                height="20"
                alt=""
              />
          </div>
          <div className="list-group-item d-flex justify-content-between border-bottom-0">
            Bereavement and loss
              <img
                src={tick}
                width="20"
                height="20"
                alt=""
              />
          </div>
          <div className="list-group-item d-flex justify-content-between border-bottom-0">
            Divorce and Seperation
              <img
                src={tick}
                width="20"
                height="20"
                alt=""
              />
          </div>
          <div className="list-group-item d-flex justify-content-between border-bottom-0">
            Cancer
              <img
                src={tick}
                width="20"
                height="20"
                alt=""
              />
          </div>
          <div className="list-group-item d-flex justify-content-between border-bottom-0">
            Abuse - Emotional, Physical, Sexual
              <img
                src={tick}
                width="20"
                height="20"
                alt=""
              />
          </div>
          <div className="list-group-item d-flex justify-content-between border-bottom-0">
            Guilt and Shame
              <img
                src={tick}
                width="20"
                height="20"
                alt=""
              />
          </div>
        </div>
        </Col>
        <Col xs={12} sm={{span:8,offset:2}} md={{span:10, offset:1}} lg={{span:6,offset:3}} className="my-3">
          <Image src={plants} fluid alt=""/>
        </Col>
      </Row>
    </Container>
  )
}

export default HelpList
