import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Media from 'react-bootstrap/Media'

import ava1 from "../images/avatar-1.png"
import ava2 from "../images/avatar-2.png"
import ava3 from "../images/avatar-3.png"
import ava4 from "../images/avatar-4.png"

const Testimonials = props => {
  return(
    <Container fluid="xl" id="testimonials">
      <Row className="my-5">
        <Col xs={12} lg={{span:10, offset:1}} className="mt-3 mb-5 text-center">
          <h1 className="mb-1">What do people who have had therapy think?</h1>
          <p className="small">In order to protect confidentiality some personally identifying information has been changed</p>
        </Col>
        <Col xs={12} sm={{span:10,offset:1}} md={{span:8, offset:2}}>
          <ul className="list-unstyled">
            <Media as="li" className="mb-5">
              <img
                width={64}
                height={64}
                className="mr-3 rounded border border-primary shadow-sm"
                src={ava1}
                alt=""
              />
              <Media.Body>
                <h5 className="mb-1">Bronwyn</h5>
                <h6 className="mt-0 mb-1">via Facebook</h6>
                <p>
                I started working with Alex as I felt I was struggling with a small aspect of my life. I soon realised that there was an awful lot of baggage from the past that I was carrying round with me and it was making my everyday life difficult. Alex helped me unpack it and go through it. She is a warm and caring person and it makes the whole process so much easier. She guided me on this journey; she was a companion; understanding and supportive, not a cold and analytical therapist who shows no emotions and takes notes while you share the most intimate details of your life. I am ready to embark on the rest of my journey with a little backpack leaving the weight of bad memories and hurtful experience behind me. Thank you Alex.
                </p>
              </Media.Body>
            </Media>

            <Media as="li" className="mb-5">
              <img
                width={64}
                height={64}
                className="mr-3 rounded border border-primary shadow-sm"
                src={ava2}
                alt=""
              />
              <Media.Body>
                <h5 className="mb-1">Mackenzie</h5>
                <h6 className="mt-0 mb-1">via Facebook</h6>
                <p>
                  I will always hold dear in my heart the really memorable moments where I've felt a real shift in my mindset in those major realisation stages. I am definitely on a different journey now and feel so much stronger and more importantly- happier in who I am as a person. I can never thank you enough and I will never forget you.
                </p>
              </Media.Body>
            </Media>

            <Media as="li" className="mb-5">
              <img
                width={64}
                height={64}
                className="mr-3 rounded border border-primary shadow-sm"
                src={ava3}
                alt=""
              />
              <Media.Body>
                <h5 className="mb-1">Billy</h5>
                <h6 className="mt-0 mb-1">via Facebook</h6>
                <p>
                  Alex is a person who really cares for other people. I lost my mother coming up to 3 years ago and she helped me through thick and thin. She helped me cross that line back over to the positive side and for that I will always be grateful. Alex also helped me with my depression and anxiety and if it wasn’t for Alex helping me through these rough patches and depressed times I wouldn’t be here to tell the tale.
                </p>
              </Media.Body>
            </Media>

            <Media as="li">
              <img
                width={64}
                height={64}
                className="mr-3 rounded border border-primary shadow-sm"
                src={ava4}
                alt=""
              />
              <Media.Body>
                  <h5 className="mb-1">Mia</h5>
                <h6 className="mt-0 mb-1">via Facebook</h6>
                <p>
                  I’ve had a lovely experience throughout my work with Alex. I’ve learnt things about myself that I never knew and you’ve gotten me out of a dark place and into a place of endless possibilities. Thank you.
                </p>
              </Media.Body>
            </Media>

          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default Testimonials
